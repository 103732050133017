const $ = require('jquery');
window.$ = window.jQuery = $;

import '../styles/index.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

import simpleParallax from 'simple-parallax-js';

import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import 'fullpage.js/dist/fullpage.css';

import './components/sliders';
import './components/odds';

import './components/functionality';

import scrollToSection from './components/sliders';

const burgerBtn = document.querySelector('.burger_btn');
const mobileMenu = document.querySelector('.navigation');
const parallaxClouds = document.getElementsByClassName('cloud-parallax');

AOS.init({
  anchorPlacement: 'top',
  duration: 1000,
  once: true,
  disable: function () {
    const maxWidth = 1200;
    return window.innerWidth < maxWidth;
  },
});

burgerBtn.addEventListener('click', function () {
  this.classList.toggle('open');
  mobileMenu.classList.toggle('mobile');
});

new simpleParallax(parallaxClouds, {
  delay: 0.6,
  transition: 'cubic-bezier(0,0,0,1)',
  scale: 1.3,
});

new fullpage('#startup-hub', {
  licenseKey: '5FD2D801-A2D24F48-9BFCC97E-0ABAAF34',
  navigation: false,
  scrollBar: true,
  controlArrows: false,
  scrollingSpeed: 2000,
  paddingTop: '70px',
  responsiveWidth: 1200,
  anchors: ['hero', 'odds-slider', 'this-way', 'never-tell', 'team', 'why-join-us', 'contacts', 'reviews', 'who-can-apply' , 'application-process', 'faq', 'footer'],
  resetSliders: true,
  loopHorizontal: false,
  bigSectionsDestination: 'top',
  slideSelector: 'fp-slider',
  fixedElements: '.header',
  onLeave: function (origin, destination, direction) {
    if ($(window).width() >= 1200) {
      const isActiveLastSlide = $(
        '.slider-right__wrapper .slick-track > .slick-slide:last-child'
      ).hasClass('slick-current');
      const odds = $('.odds').is(':visible');

      if (destination.anchor === 'odds-slider' && !isActiveLastSlide) {
        return setTimeout(function () {
          $('.slider-left__wrapper')
            .slick('slickSetOption', 'autoplay', true)
            .slick('slickPlay');
        }, 500);
      }

      if (destination.anchor === 'odds-slider' && isActiveLastSlide && !odds) {
         return setTimeout(function () {
          scrollToSection();
        }, 5000);
      } return $('.slider-left__wrapper').slick('slickPause');
    }
  },
});
