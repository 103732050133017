const $oddsNumbers = $('.odds__circle .number');
const $oddsNumbersText = $('.odds .odds__text');

$oddsNumbers.on('click', function (e) {
  const numberClass = $(e.target).attr('class').split(' ')[1];

  $(this).siblings().removeClass('hover');
  $(this).toggleClass('hover');

  if ($(window).width() < 1200) {

    if ($(this).hasClass('hover')) {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('.odds__circle .text-wrapper').offset().top - 75,
        },
        1000
      );
    }
  }

  compare(numberClass);
});

function compare(className) {
  $oddsNumbersText.each(function () {
    $(this).hasClass(className)
      ? $(this).fadeToggle(500)
      : $(this).fadeOut(400);
  });
}
