import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

//reviews slider

$('.reviews-slider').slick({
  arrows: false,
  dots: true,
  speed: 700,
  fade: true,
  cssEase: 'linear',
  adaptiveHeight: false,
  appendDots: $('.reviews .reviews-dots'),
  asNavFor: '.img-slides-wrapper__items',
});

$('.img-slides-wrapper__items').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.reviews-slider',
  speed: 700,
  dots: false,
  arrows: false,
  fade: true,
  cssEase: 'linear',
});

// team slider

$('.text__container').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  asNavFor: '.team__container',
  speed: 500,
  autoplaySpeed: 5000,
  draggable: false,
  swipe: false,
});

$('.team__container').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.text__container',
  dots: true,
  arrows: false,
  centerMode: true,
  focusOnSelect: true,
  centerPadding: 0,
  speed: 700,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: 50,
      },
    },
  ],
});

$('.team__container .slick-slide').on('click', function () {
  $('.text__container')
    .slick('slickSetOption', 'autoplay', true)
    .slick('slickPlay');
});

//odds slider

$('.odds-slider .slider-left__wrapper').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.odds-slider .slider-right__wrapper',
  dots: false,
  vertical: true,
  speed: 1000,
  verticalSwiping: true,
  prevArrow: $('.prev'),
  nextArrow: $('.next'),
  autoplaySpeed: 2000,
  infinite: false,
  draggable: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        dots: true,
        vertical: false,
        adaptiveHeight: true,
        verticalSwiping: false,
      },
    },
  ],
});

$('.odds-slider .slider-right__wrapper')
  .on(
    'beforeChange',
    function (event, { slideCount: count }, currentSlide, nextSlide) {
      let selectors = [nextSlide, nextSlide - 1, nextSlide - 2]
        .map((n) => `[data-slick-index="${n - 1}"]`)
        .join(',');
      $(selectors).addClass('transform');

      if (currentSlide > nextSlide) {
        let diff = currentSlide - nextSlide;

        if (diff === 1) {
          return $('.slick-slide.slick-current.slick-center')
            .prev()
            .removeClass('transform');
        }
        return $('.slick-slide.slick-current.slick-center')
          .prevAll()
          .removeClass('transform');
      }
    }
  )
  .slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.odds-slider .slider-left__wrapper',
    dots: false,
    arrows: false,
    centerMode: true,
    infinite: false,
    speed: 1000,
    draggable: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

$('.odds-slider .slider-right__wrapper').on(
  'afterChange',

  function (event, slick, currentSlide) {
    const odds = $('.odds').is(':visible');
    const isActiveSlider = $('.odds-slider').hasClass('active');

    if (slick.$slides.length - 1 === currentSlide && !odds && isActiveSlider) {
      setTimeout(function () {
        scrollToSection();
      }, 1000);
      $('.slider-left__wrapper').slick('slickPause');
    }

    if (slick.$slides.length - 1 === currentSlide && odds) {
      $('.slider-left__wrapper').slick('slickPause');
    }

    if (currentSlide === 0) {
      $('.slider-left__wrapper').slick('slickPause');
    }
  }
);

$('.odds-slider .custom-arrows .prev, .odds-slider .custom-arrows .next').on(
  'click',
  function () {
    $('.slider-left__wrapper')
      .slick('slickSetOption', 'autoplay', true)
      .slick('slickPlay');
  }
);

$('.odds-slider .slider-left .btn-wrapper > a').on('click', function () {
  scrollToSection();
});

export default function scrollToSection() {
  $('#odds')
    .fadeIn()
    .addClass('section fp-section fp-table')
    .css('padding-top', '75px');
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $('#odds').offset().top,
    },
    1000
  );

  AOS.refresh();
}
