import { throttle } from '../utils/throttle';

const page404 = $('#page-404');
const page505 = $('#page-505');
const animationSpeed = 500;
let disabledClick = false;
let timeout;

$(window).on('resize', throttle(hideMenuOnClick, 500));

$('.team__container .slick-slide img').on('click', function () {
  if (disabledClick) {
    return false;
  }

  disabledClick = true;

  timeout = setTimeout(function () {
    disabledClick = false;
  }, animationSpeed);
});

function hideMenuOnClick() {
  if ($(window).width() < 768) {
    $('.header .navigation > a').on('click', function () {
      $('.burger_btn').removeClass('open');
      $('nav.navigation').removeClass('mobile');
    });
  }
}

if (page404.length || page505.length) {
  $('.header .navigation > a, a.logo').each(function () {
    const oldUrl = $(this).attr('href');
    const newUrl = `/${oldUrl}`;

    $(this).attr('href', newUrl);
  });
}

hideMenuOnClick();
